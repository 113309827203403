import React from "react";

import { Widget, CategoryWidget } from "../../components";

// Widgets Data
import widgetsData from "../../data/widgets.json";
const { manpowerLists } = widgetsData;

// FAQ Data

const ManpowerList = ({ ...restProps }) => (
  <div {...restProps}>
    <div className="container">
      <div className="row flex-row-reverse">
        <div className="col-lg-12">
          <Widget widgetTitle="Man Power services" className="widget_nav_menu">
            <CategoryWidget>
              {manpowerLists.map((item) => (
                <CategoryWidget.Item key={item} path="">
                  <span className="btn-img">
                    <img
                      style={{ width: "100px", height: "70px" }}
                      src={item.path}
                      alt="tabicon"
                    />
                  </span>
                  <span className="btn-title h6 p-4">{item.name}</span>
                  {/* {cat} */}
                </CategoryWidget.Item>
              ))}
            </CategoryWidget>
          </Widget>
        </div>
        {/* <div className="col-lg-4">
          <SidebarTwo />
        </div> */}
      </div>
    </div>
  </div>
);

export default ManpowerList;
