import React from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { InfoMedia, FormFive } from "../../components";

const ContactTwo = ({ ...restProps }) => (
  <div {...restProps}>
    <div className="container">
      <Tab.Container id="contactTab" defaultActiveKey="tabno1">
        <Nav className="contact-tab-menu">
          <Nav.Link>
            <span className="btn-img">
              <img
                src="https://ik.imagekit.io/nce63ejyh/Alkas/contact-1-2.jpg?updatedAt=1707735032336"
                alt="tabicon"
              />
            </span>
            <span className="btn-title h6">Alkas, Saudi Arabia</span>
            <span className="btn-text">
              #301 B, 3rd Floor Gulf Star Business Center, Jubail, Saudi Arabia
            </span>
          </Nav.Link>
          <Nav.Link>
            <span className="btn-img">
              <img
                src="https://ik.imagekit.io/nce63ejyh/Alkas/contact-1-1.jpg?updatedAt=1707734947018"
                alt="tabicon"
              />
            </span>
            <span className="btn-title h6">Alkas, Dubai</span>
            <span className="btn-text"></span>
          </Nav.Link>

          <Nav.Link>
            <span className="btn-img">
              <img
                src="https://ik.imagekit.io/nce63ejyh/Alkas/contact-1-3.jpg?updatedAt=1707734947062"
                alt="tabicon"
              />
            </span>
            <span className="btn-title h6">Alkas, Qatar</span>
            <span className="btn-text">
              Office No 308, 3rd Floor Street No. 231, Zone 38 Al Suhaim Tower,
              Al Sadd Doha - Qatar
            </span>
            <span className="btn-text">
              <a className="btn-text" href="tel:+97450305678">
                +97450305678
              </a>
            </span>
          </Nav.Link>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="tabno1">
            <div className="row">
              <div className="col-lg-6 mb-30">
                <div className="contact-box">
                  <h3 className="contact-box__title h4">
                    Jubail Office Address
                  </h3>
                  <p className="contact-box__text">
                    Elevate operational excellence with compliant metrics and
                    dynamic construction solutions. Connect with us to redefine
                    collaboration. Start now.
                  </p>
                  <InfoMedia
                    icon="fal fa-phone-alt"
                    title="Phone Number & Email"
                    info={
                      <>
                        <a href="tel:+966592024721">(+966) 592024721</a>
                        <a href="mailto:info@alkas-group.com">
                          info@alkas-group.com
                        </a>
                      </>
                    }
                  />
                  <InfoMedia
                    icon="far fa-map-marker-alt"
                    title="Our Office Address"
                    info="#301 B, 3rd Floor Gulf Star Business Center, Jubail, Saudi Arabia"
                  />
                  <InfoMedia
                    icon="far fa-clock"
                    title="Official Work Time"
                    info="7:00am - 6:00pm ( Mon - Sat )  Sun & Holiday Closed"
                  />
                </div>
              </div>
              <div className="col-lg-6 mb-30">
                <div className="contact-box">
                  <h3 className="contact-box__title h4">Leave a Message</h3>
                  <p className="contact-box__text">We’re Ready To Help You</p>
                  <FormFive />
                </div>
              </div>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="tabno2">
            <div className="row">
              <div className="col-lg-6 mb-30">
                <div className="contact-box">
                  <h3 className="contact-box__title h4">
                    Australia Office Address
                  </h3>
                  <p className="contact-box__text">
                    Completely recaptiualize 24/7 communities via standards
                    compliant metrics whereas web-enabled content
                  </p>
                  <InfoMedia
                    icon="fal fa-phone-alt"
                    title="Phone Number & Email"
                    info={
                      <>
                        <a href="tel:+310259121563">+(310) 2591 21563</a>
                        <a href="mailto:info@example.com">info@example.com</a>
                      </>
                    }
                  />
                  <InfoMedia
                    icon="far fa-map-marker-alt"
                    title="Our Office Address"
                    info="258 Dancing Street, Miland Line, HUYI 21563, Canberra"
                  />
                  <InfoMedia
                    icon="far fa-clock"
                    title="Official Work Time"
                    info="7:00am - 6:00pm ( Mon - Fri ) Sat, Sun & Holiday Closed"
                  />
                </div>
              </div>
              <div className="col-lg-6 mb-30">
                <div className="contact-box">
                  <h3 className="contact-box__title h4">Leave a Message</h3>
                  <p className="contact-box__text">We’re Ready To Help You</p>
                  <FormFive />
                </div>
              </div>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="tabno3">
            <div className="row">
              <div className="col-lg-6 mb-30">
                <div className="contact-box">
                  <h3 className="contact-box__title h4">
                    United State Office Address
                  </h3>
                  <p className="contact-box__text">
                    Completely recaptiualize 24/7 communities via standards
                    compliant metrics whereas web-enabled content
                  </p>
                  <InfoMedia
                    icon="fal fa-phone-alt"
                    title="Phone Number & Email"
                    info={
                      <>
                        <a href="tel:+310259121563">+(310) 2591 21563</a>
                        <a href="mailto:info@example.com">info@example.com</a>
                      </>
                    }
                  />
                  <InfoMedia
                    icon="far fa-map-marker-alt"
                    title="Our Office Address"
                    info="258 Dancing Street, Miland Line, HUYI 21563, NewYork"
                  />
                  <InfoMedia
                    icon="far fa-clock"
                    title="Official Work Time"
                    info="7:00am - 6:00pm ( Mon - Fri ) Sat, Sun & Holiday Closed"
                  />
                </div>
              </div>
              <div className="col-lg-6 mb-30">
                <div className="contact-box">
                  <h3 className="contact-box__title h4">Leave a Message</h3>
                  <p className="contact-box__text">We’re Ready To Help You</p>
                  <FormFive />
                </div>
              </div>
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  </div>
);

export default ContactTwo;
