import React from "react";
import {
  TitleWrap,
  SecTitle,
  SecSubTitle,
  FeatureBoxTwo,
} from "../../components/";
import Slider from "react-slick";

const FeatureTwo = ({ className }) => {
  const settings = {
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          vertical: true,
          verticalSwiping: true,
        },
      },
    ],
  };

  return (
    <div className={`feature-wrap2 ${className || ""}`}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xxl-3 text-center text-xxl-start">
            <TitleWrap>
              <SecSubTitle>
                <i className="fas fa-bring-forward" />
                Why Choose Us
              </SecSubTitle>
              {/* <SecTitle className="h1 text-capitalize">
                Why People Choose Our Services
              </SecTitle> */}
            </TitleWrap>
          </div>
          <div className={`col-xxl-12 ${className}`}>
            {/* <Slider className="row feature-slide2" {...settings}> */}
            <div className="feature-container">
              <FeatureBoxTwo
                title="Construction & Industrial Support Service"
                image="https://ik.imagekit.io/nce63ejyh/Alkas/construction.png?updatedAt=1729743169833"
                text="Providing technology-driven solutions to streamline construction and industrial operations for peak efficiency"
                path="/services"
              />
              <FeatureBoxTwo
                title="Man Power"
                image="https://ik.imagekit.io/nce63ejyh/Alkas/manpower.png?updatedAt=1729742862053"
                text="Providing skilled manpower solutions to enhance construction and industrial operations for peak efficiency"
                path="/manpower-list"
              />
              <FeatureBoxTwo
                title="Trading"
                image="https://ik.imagekit.io/nce63ejyh/Alkas/trading.png?updatedAt=1729742992697"
                text="Providing reliable trading solutions to support construction and industrial operations for peak efficiency."
                path="/trading-list"
              />
              <FeatureBoxTwo
                title="Equipment"
                image="https://ik.imagekit.io/nce63ejyh/Alkas/equipment.png?updatedAt=1729742992538"
                text="Providing advanced equipment solutions to enhance construction and industrial operations for peak efficiency."
                path="/equipment-list"
              />
            </div>
            {/* </Slider> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureTwo;
