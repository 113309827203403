import React from "react";
import { Link } from "react-router-dom";
import {
  InfoMedia,
  Widget,
  AboutWidget,
  CategoryWidget,
  // RecentPostWidget,
  IconLink,
} from "../../components/";

// Widget Datas
import widgetsdata from "../../data/widgets.json";

const {
  pageList,
  exploreLinks,
  //  recentPosts
} = widgetsdata;

const FooterOne = () => (
  <footer
    className="footer-wrapper footer-layout1 background-image"
    style={{ backgroundImage: "url(images/shape/bg-footer-1-1.jpg)" }}
  >
    <div className="footer-top">
      <div className="container">
        <div className="row">
          <div className="footer-media col-sm">
            <InfoMedia
              icon="fal fa-map-marker-alt"
              title="Office Address"
              info="#301 B, 3rd Floor Gulf Star Business Center, jubail, Saudi Arabia"
            />
          </div>
          <div className="footer-media col-sm">
            <InfoMedia
              icon="fal fa-clock"
              title="Working Hours"
              info="Weekdays 8am - 5pm Weekend Holiday"
            />
          </div>
          <div className="footer-media col-sm">
            <InfoMedia
              icon="fal fa-phone-volume"
              title="Contact Us"
              info={
                <>
                  <a href="mailto:info@alkas.com">info@alkas-group.com</a>
                  <br />
                  <a href="mailto:info@alkas.com">sales@alkas-group.com</a>
                  <br />
                  <a href="tel:+966592024721">(+966) 592024721</a>
                </>
              }
            />
          </div>
        </div>
      </div>
    </div>
    <div className="widget-area">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-md-6 col-xl-auto">
            <Widget widgetTitle="About Us" className="footer-widget">
              <AboutWidget>
                <AboutWidget.Text>
                  As a premier construction and manpower provider, we excel in
                  precision, integrity, and operational excellence, ensuring a
                  trusted partner for your project's success.
                </AboutWidget.Text>
                <IconLink className="footer-social">
                  <IconLink.Item icon="fab fa-facebook-f" path="/" />
                  <IconLink.Item icon="fab fa-youtube" path="/" />
                  <IconLink.Item icon="fab fa-instagram" path="/" />
                </IconLink>
              </AboutWidget>
            </Widget>
          </div>
          <div className="col-6 col-xl-auto">
            <Widget
              widgetTitle="Services"
              className="widget_nav_menu footer-widget"
            >
              <CategoryWidget>
                {pageList.map((cat) => (
                  <CategoryWidget.Item key={cat.name} path={cat.path}>
                    {cat.name}
                  </CategoryWidget.Item>
                ))}
              </CategoryWidget>
            </Widget>
          </div>
          <div className="col-6 col-xl-auto">
            <Widget
              widgetTitle="Explore"
              className="widget_nav_menu footer-widget"
            >
              <CategoryWidget>
                {exploreLinks.map((cat) => (
                  <CategoryWidget.Item key={cat.name} path={cat.path}>
                    {cat.name}
                  </CategoryWidget.Item>
                ))}
              </CategoryWidget>
            </Widget>
          </div>
          <div className="col-md-6 col-xl-auto">
            {/* <Widget widgetTitle="Lastest Post" className="footer-widget">
              {recentPosts.slice(0, 2).map((post) => (
                <RecentPostWidget
                  key={post.id}
                  title={post.title}
                  image={post.image}
                  date={post.date}
                />
              ))}
            </Widget> */}
          </div>
        </div>
      </div>
    </div>
    <div className="copyright-wrap">
      <div className="container">
        <p className="copyright-text">
          Copyright <i className="fal fa-copyright" />{" "}
          {new Date().getFullYear()}{" "}
          <Link className="text-white" to="/">
            Alkas Arabia
          </Link>
          . All rights reserved. Developed by{" "}
          <a className="text-white" href="https://kliffto.com/">
            Kliffto
          </a>
          .
        </p>
      </div>
    </div>
  </footer>
);

export default FooterOne;
