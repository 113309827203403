import React from "react";
import { Widget, CategoryWidget, BannerWidget } from "../../components";

// Widgets Data
import widgetsData from "../../data/widgets.json";
const { workinghours, constructionList } = widgetsData;

const SidebarTwo = ({ className }) => (
  <aside className={`service-sidebar ${className || ""}`}>
    <Widget widgetTitle="All Services" className="widget_nav_menu">
      <CategoryWidget>
        {constructionList.map((cat) => (
          <CategoryWidget.Item key={cat.name} path="">
            {cat.name}
          </CategoryWidget.Item>
        ))}
      </CategoryWidget>
    </Widget>
    <Widget widgetTitle="All Services" className="widget-workhours">
      <CategoryWidget>
        {workinghours.map((item, index) => (
          <CategoryWidget.Item key={index} path="/">
            <i className="fal fa-clock" />
            {item}
          </CategoryWidget.Item>
        ))}
      </CategoryWidget>
    </Widget>
    <BannerWidget
      title="Have Any Query?"
      image="https://ik.imagekit.io/nce63ejyh/Alkas/quote-box-min.jpg?updatedAt=1707736716879"
      btnText="Get A Quote"
      path="/contact"
    />
  </aside>
);

export default SidebarTwo;
