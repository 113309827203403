import React from "react";
import {
  SecSubTitle,
  SecTitle,
  Button,
  InfoMedia,
  ImageBoxThree,
} from "../../components";

const about2 =
  "https://ik.imagekit.io/nce63ejyh/Alkas/shanghai-developing.jpg?updatedAt=1729413520487";
const about1 =
  "https://ik.imagekit.io/nce63ejyh/Alkas/october-2018-building-construction-skyscrapers-dubai.jpg?updatedAt=1729413520598";

const AboutThree = () => (
  <div
    className="about-wrap1 background-image"
    style={{ backgroundImage: "url(images/bg/ab-bg-2-1.jpg)" }}
  >
    <div className="container">
      <div className="row gx-60">
        <div className="col-xl-6 mb-50 mb-xl-0">
          <ImageBoxThree
            imageOne={about1}
            imageTwo={about2}
            number="100%"
            title="Client Satisfaction"
            icon="fal fa-award"
          />
        </div>
        <div className=" col-xl-6 align-self-center text-center text-xl-start">
          <SecSubTitle>
            <i className="fas fa-bring-forward" />
            ABOUT OUR COMPANY
          </SecSubTitle>
          <SecTitle>Empowering Construction and Manpower Excellence</SecTitle>
          <p className="mb-4 pb-2 pe-xl-5">
            In the dynamic landscape of construction and manpower, our firm
            stands as a beacon, dedicated to empowering excellence by seamlessly
            bridging the gap between skilled labor and innovative solutions. We
            recognize that in today's fast-paced industry, the synergy between
            human expertise and cutting-edge technology is paramount.
          </p>
          <div className="row gx-60 mb-4 pb-1 gy-2 text-start justify-content-center justify-content-xl-start">
            <div className="col-auto">
              <InfoMedia
                className="about-media"
                image="images/icon/ab-ic-2-1.png"
                info="Provide Skills Services"
              />
            </div>
            <div className="col-auto">
              <InfoMedia
                className="about-media"
                image="images/icon/ab-ic-2-2.png"
                info="Urgent Support For Clients"
              />
            </div>
          </div>
          <Button path="/contact">
            Get A Quote
            <i className="far fa-arrow-right" />
          </Button>
        </div>
      </div>
    </div>
  </div>
);

export default AboutThree;
