import React from "react";
import { SecTitle, SecSubTitle, InfoMedia, FormOne } from "../../components";

const ContactOne = () => (
  <div
    className="background-image"
    style={{
      backgroundImage:
        "url(https://ik.imagekit.io/nce63ejyh/Alkas/appoin-bg-1-1.jpg?updatedAt=1705220710070)",
    }}
  >
    <div className="container z-index-common">
      <div className="row gx-60">
        <div className="col-xl-6 align-self-center space">
          <SecSubTitle className="text-white">Make An Appointment</SecSubTitle>
          <SecTitle className="text-capitalize h1 text-white">
            We Provide Best Advice For Your Future
          </SecTitle>
          <hr className="hr-style1" />
          <p className="mb-4 mt-1 pb-3 text-white">
            Seize the opportunity to optimize your project success—schedule an
            appointment today, and let us tailor our construction and manpower
            expertise to meet your specific needs.
          </p>
          <div className="row gy-30">
            <div className="col-md-6">
              <InfoMedia
                className="contact-media"
                icon="fal fa-phone"
                title="CALL for Emergency "
                info={
                  <>
                    <a href="tel:+966592024721">(+966) 592024721</a>
                  </>
                }
              />
            </div>
            <div className="col-md-6">
              <InfoMedia
                className="contact-media"
                icon="fal fa-envelope"
                title="Write a Quick mail"
                info={
                  <a href="mailto:info@alkas-group.com">info@alkas-group.com</a>
                }
              />
            </div>

            <div className="col-md-6">
              <InfoMedia
                className="contact-media"
                icon="fal fa-location"
                title="VISITE Saudi OFFICE"
                info="#301 B, 3rd Floor Gulf Star Business Center, jubail, Saudi Arabia"
              />
            </div>
          </div>
        </div>
        <div className="col-xl-6 form-wrap1">
          <FormOne title="Make An Appointment" btnText="Make An Appointment" />
        </div>
      </div>
    </div>
  </div>
);

export default ContactOne;
