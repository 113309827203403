import React from "react";
import { InfoMedia } from "../../components";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

import counterbg1 from "../../assets/images/counterbg/counter-bg-2-1.jpg";

const CounterTwo = ({ className }) => {
  // Initialize intersection observer
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger only once
    threshold: 0.1, // Trigger when 10% of the component is visible
  });

  return (
    <div
      className={`background-image ${className || ""}`}
      style={{
        backgroundImage:
          "url(https://ik.imagekit.io/nce63ejyh/Alkas/counter-bg-2-1.jpg?updatedAt=1705218493295)",
      }}
      ref={ref} // Attach the ref for visibility detection
    >
      <div className="container">
        <div className="row justify-content-between gy-4">
          <div className="col-6 col-lg-auto">
            <InfoMedia
              className="counter-media"
              image="images/icon/count-1-1.png"
              title={
                inView ? (
                  <CountUp start={0} end={700} duration={4} suffix="+" />
                ) : (
                  "0+"
                )
              }
              info="Successful Projects"
            />
          </div>
          <div className="col-6 col-lg-auto">
            <InfoMedia
              className="counter-media"
              image="images/icon/count-1-2.png"
              title={
                inView ? (
                  <CountUp start={0} end={16} duration={4} suffix="+" />
                ) : (
                  "0+"
                )
              }
              info="Years Experience"
            />
          </div>
          <div className="col-6 col-lg-auto">
            <InfoMedia
              className="counter-media"
              image="images/icon/count-1-3.png"
              title={
                inView ? (
                  <CountUp start={0} end={400} duration={4} suffix="+" />
                ) : (
                  "0+"
                )
              }
              info="Skilled Experts"
            />
          </div>
          <div className="col-6 col-lg-auto">
            <InfoMedia
              className="counter-media"
              image="images/icon/count-1-4.png"
              title={
                inView ? (
                  <CountUp start={0} end={500} duration={4} suffix="+" />
                ) : (
                  "0+"
                )
              }
              info="Happy Clients"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CounterTwo;
