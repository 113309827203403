import React, { Fragment } from "react";
import { Seo, Breadcrumb, ScrollTopBtn } from "../components";
import { HeaderOne, FooterOne } from "../containers";
import AlkasServiceContainer from "../containers/Services/AlkasServiceContainer";

const AlkasServices = () => (
  <Fragment>
    <Seo title="Services Details" />
    <HeaderOne />
    <Breadcrumb
      pageName="Services Details"
      bgImage="https://ik.imagekit.io/nce63ejyh/Alkas/close-up-men-work-with-laptop-min.jpg?updatedAt=1707764592254"
    />
    <AlkasServiceContainer className="space-top space-extra-bottom" />
    <FooterOne />
    <ScrollTopBtn />
  </Fragment>
);

export default AlkasServices;
