import React from "react";
import {
  ImageBoxFour,
  SecTitle,
  SecSubTitle,
  InfoMedia,
} from "../../components";

const ab3 =
  "https://ik.imagekit.io/nce63ejyh/Alkas/happy-workers-construction-site-young-civil-engineer-manager-architects-handshaking-construction-site-looking-construction-phase-cooperation-teamwork-concept-min.jpg?updatedAt=1707764592263";
const ab4 =
  "https://ik.imagekit.io/nce63ejyh/Alkas/people-safety-equipment-working-min.jpg?updatedAt=1707762355660";

const AboutFour = ({ ...restProps }) => (
  <div {...restProps}>
    <div className="container">
      <div className="row flex-row-reverse">
        <div className="col-lg-6 mb-30">
          <ImageBoxFour imageOne={ab3} imageTwo={ab4} />
        </div>
        <div className="col-lg-6 mb-30 pt-10 pt-lg-0 text-center text-md-start">
          <SecSubTitle>
            <i className="fas fa-bring-forward" />
            Get best Construction & Manpower services
          </SecSubTitle>
          <SecTitle className="h1 mb-3 pb-3 text-capitalize">
            Ready To Get Our Best Quality Services
          </SecTitle>
          <InfoMedia
            className="media-order"
            number="01"
            title="We Deliver Perfect Solution"
            info="At our firm, we pride ourselves on delivering the perfect solution meticulously crafted, tailored to your unique needs. Our unwavering commitment to excellence ensures that every challenge is met with precision and innovation..."
          />
          <InfoMedia
            className="media-order"
            number="02"
            title="Specialised Support Service"
            info="Experience unparalleled peace of mind with our specialized support service, designed to cater to your unique needs. Our dedicated team is committed to delivering exceptional assistance, ensuring your journey is marked by seamless efficiency and satisfaction..."
          />
          <InfoMedia
            className="media-order"
            number="03"
            title="Professional Approach"
            info="Embrace a journey marked by precision and trust with our professional approach. We navigate complexities with expertise, ensuring a seamless experience that reflects our commitment to excellence..."
          />
          <InfoMedia
            className="media-order"
            number="04"
            title="Wide Exposure To Marke"
            info="Gain a competitive edge with our services offering wide exposure to markets. Our strategic approach ensures your business reaches new horizons, tapping into diverse opportunities for growth and success..."
          />
        </div>
      </div>
    </div>
  </div>
);

export default AboutFour;
